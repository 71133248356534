import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

const usePrismicNewsReports = () => {
  const { language } = useI18next();
  const newLang =
    language === "en" ? "en-us" : language === "ru" ? "ru" : "hy-am";

  const response = useStaticQuery(graphql`
    query PrismicNewsReports {
      allPrismicNewsreports(filter: { tags: { in: "news_reports" } }) {
        nodes {
          lang
          data {
            body {
              ... on PrismicNewsreportsDataBodyReportsByYear {
                id
                slice_label
                slice_type
                primary {
                  year
                }
                items {
                  type
                  mont_quarter_year
                  link {
                    url
                  }
                }
              }
            }
            body2 {
              ... on PrismicNewsreportsDataBody2ConferencesAndUsefulLinks {
                id
                slice_type
                primary {
                  id
                  title1 {
                    text
                  }
                  descriptionconference {
                    richText
                  }
                  date
                  image {
                    url
                  }
                  useful_link_title {
                    text
                  }
                  useful_link_description {
                    richText
                  }
                }
                items {
                  link_title
                  link_href {
                    url
                  }
                }
              }
            }
            is_show_events
            is_show_news
            navtext
            title {
              text
            }
            calendartitle {
              text
            }
            events {
              id
              title2 {
                text
              }
              description {
                richText
              }
              date
            }
            calendarbutton
            reporttitle {
              text
            }
            description {
              richText
            }
            monthly {
              text
            }
            allreportbuttontext
            quarterly {
              text
            }
            yearly {
              text
            }
            conferencetitle {
              text
            }
            reportcover {
              url
            }
            titlenews
            buttontext
          }
        }
      }
    }
  `);

  return sanitize(
    response.allPrismicNewsreports.nodes.filter(
      (item) => item.lang === newLang
    )[0].data
  );
};

const sanitize = (response) => {
  return {
    allReportButtonText: response.allreportbuttontext,
    calendarButton: response.calendarbutton,
    titleNews: response.titlenews,
    buttonText: response.buttontext,
    calendarTitle: response.calendartitle.text,
    conferenceTitle: response.conferencetitle.text,
    description: response.description.richText,
    monthly: response.monthly.text,
    yearly: response.yearly.text,
    navText: response.navtext,
    quarterly: response.quarterly.text,
    reportCover: response.reportcover.url,
    reportTitle: response.reporttitle.text,
    title: response.title.text,
    conferences: response.body2.map((conference) => ({
      id: conference.primary.id,
      image: conference.primary.image.url,
      title: conference.primary.title1.text,
      description: conference.primary.descriptionconference.richText,
      date: conference.primary.date,
      usefulLinks: {
        id: conference.primary.id,
        title: conference.primary.useful_link_title.text,
        description: conference.primary.useful_link_description.richText,
        links: conference.items.map((link) => ({
          title: link.link_title,
          href: link.link_href.url,
        })),
      },
    })),
    isShowEvent: response.is_show_events === "Yes",
    isShowNews: response.is_show_news === "Yes",
    events: response.events.map((event) => ({
      id: event.id,
      description: event.description.richText,
      title2: event.title2.text,
      date: event.date,
    })),
    reports: collectReports(response.body),
  };
};

const collectReports = (data) => {
  let reports = {};
  data
    .filter((report) => report.items.length > 0)
    .forEach((report) => {
      let date = report.primary.year;
      if (date in reports) {
        reports[date].push({
          id: report.id,
          ...report.items[0],
        });
      } else {
        reports[date] = [
          {
            id: report.id,
            ...report.items[0],
          },
        ];
      }
    });

  return reportsObjectIntoArray(reports);
};

const reportsObjectIntoArray = (array) => {
  const reportsArray = Object.keys(array)
    .filter((date) => array[date].length > 0)
    .map((date) => {
      return {
        date,
        items: array[date],
      };
    });

  reportsArray.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });
  return reportsArray;
};

export default usePrismicNewsReports;
