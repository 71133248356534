import React, { useEffect, useState } from 'react';
import { Container, Heading, Box, Flex, Image } from '@chakra-ui/react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Seo from '../SEO';
import Main from '../layouts/Main';
import SmallNav from '../smallNav';
import RichText from "../PrismicRichText";

const SinglePageTemplate = ({ title, description, image, route }) => {
  const { language } = useI18next();
  const [imgSrc, setImgSrc] = useState(null);

  // Логирование входящих пропсов
  // console.log('SinglePageTemplate props:', { title, description, image, route });

  useEffect(() => {
    const loadImage = () => {
      if (!image) {
        // console.warn('Image source is not available:', image);
        setImgSrc('/path/to/placeholder/image.jpg');
      } else {
        setImgSrc(image);
      }
    };

    const timeoutId = setTimeout(loadImage, 100); // 0.1 секунда задержки

    return () => clearTimeout(timeoutId); // Чистка таймаута при размонтировании компонента
  }, [image]);

  return (
    <Main>
      <Container
        pos="relative"
        px="5"
        mb="3.75rem"
        maxW="7xl"
      >
        <Seo
          title={title}
          description={description}
          link={`https://www.fsm.am/${language}/${route}`}
        />
        <SmallNav>
          {title}
        </SmallNav>
        <Flex mt={{ base: "3", sm: "5" }} bg="gray.100" p="5" borderRadius="8px" mb="3.75rem">
          {/* Левая часть: Heading и текст новости */}
          <Box flex="1" mr="10" style={{ textAlign: "justify" }}>
            <Heading fontSize="3xl" mb="5" textAlign="center" color="green.100">
              {title}
            </Heading>
            <RichText text={description} style={{ textAlign: "justify" }} />
          </Box>

          {/* Правая часть: Изображение новости */}
          <Box flex="1" display="flex" alignItems="center">
            {imgSrc ? (
              <Image
                objectFit="cover"
                borderRadius="8px"
                width="600px"
                height="400px"
                src={imgSrc}
                alt={title}
                // onError={(e) => {
                //   console.error('Image failed to load:', e.target.src);
                //   setImgSrc('/path/to/placeholder/image.jpg'); // Путь к заглушке изображения
                // }}
              />
            ) : (
              <Box width="600px" height="400px" backgroundColor="gray.200" />
            )}
          </Box>
        </Flex>
      </Container>
    </Main>
  );
};

export default SinglePageTemplate;



//Second case 
// import React from 'react'
// import { Container, Heading, Box, Stack, Image, VStack, Flex } from '@chakra-ui/react'
// import { useI18next } from 'gatsby-plugin-react-i18next'
// import Seo from '../SEO'
// import Main from '../layouts/Main'
// import SmallNav from '../smallNav'
// import RichText from "../PrismicRichText";
// // import { getUrlFromOldToNewBucket } from "../../helpers";

// const SinglePageTemplate = ({ title, description, image, route }) => {//Գլխավոր էջի վերջին հրապարակումների  առանձին էջերնա
//   const { language } = useI18next()


//   return (
//     <Main>
//       <Container
//         pos="relative"
//         px="5"
//         mb="3.75rem"
//         maxW="7xl"
//       >
//         <Seo
//           title={title}
//           description={description}
//           link={`https://www.fsm.am/${language}/${route}`}
//         />
//         <SmallNav>
//           {title}
//         </SmallNav>
//         <Flex mt={{ base: "3", sm: "5" }} bg="gray.100" p="5" borderRadius="8px" mb="3.75rem">
//           {/* Левая часть: Heading и текст новости */}
//           <Box flex="1" mr="10" style={{ textAlign: "justify" }}>
//             <Heading fontSize="3xl" mb="5" textAlign="center" color="green.100">
//               {title}
//             </Heading>
//             <RichText text={description} style={{ textAlign: "justify" }} />
//           </Box>

//           {/* Правая часть: Изображение новости */}
//           <Box flex="1" display="flex" alignItems="center">
//             <Image
//               objectFit="cover"
//               borderRadius="8px"
//               width="600px"
//               height="400px"
//               src={image}
//               alt={title}
//             />
//           </Box>
//         </Flex>
//       </Container>
//     </Main>
//   )
// }

// export default SinglePageTemplate


//First Original case

// import React from 'react'
// import { Container, Heading, Box, Stack, Image, VStack } from '@chakra-ui/react'
// import { useI18next } from 'gatsby-plugin-react-i18next'
// import Seo from '../SEO'
// import Main from '../layouts/Main'
// import SmallNav from '../smallNav'
// import RichText from "../PrismicRichText";

// const SinglePageTemplate = ({ title, description, image, route, usefulLinks }) => {
//   const { language } = useI18next()

//   // Рассчитываем максимальную высоту изображения как 1/3 ширины
//   //const maxWidth = "48.25rem"; // Максимальная ширина изображения
//   // const maxHeight = `calc(${maxWidth} / 3)`;

//   return (
//     <Main>
//       <Container
//         pos="relative"
//         px="5"
//         mb="3.75rem"
//         maxW="7xl"
//       >
//         <Seo
//           title={title}
//           description={description}
//           link={`https://www.fsm.am/${language}/${route}`}
//         />
//         <SmallNav>
//           {title}
//         </SmallNav>
//         <Stack direction={{ base: "column", md: "row" }} mt={{ base: '3', sm: "5" }} spacing="10rem">
//           <Box
//             w="100%"
//             borderBottom={{ base: "1px", md: "0px" }}
//             borderColor={{ base: "border.100" }}
//             pb={{ base: "2.125rem" }}//3.125
//           >
//             <VStack w="100%" spacing="6" alignItems="left" mb={{ base: "6", sm: "3.75rem" }}>
//               <Image
//                 objectFit="cover"
//                 borderRadius="0px 0px 0px 120px"
//                 width="100%"
//                 maxH="26rem"
//                 mb="1.125rem"
//                 src={image}
//               />
//             </VStack>
//           </Box>
//         </Stack>
//         <Stack direction={{ base: "column", md: "row" }} mt={{ base: '3', sm: "5" }} spacing="10rem">
//           <Box
//             w="100%"
//             borderBottom={{ base: "1px", md: "0px" }}
//             borderColor={{ base: "border.100" }}
//             pb={{ base: "3.125rem" }}
//           >
//             <VStack spacing="6" alignItems="left" mb={{ base: "6", sm: "3.75rem" }}>
//               <Heading fontSize="3xl" mb="5" textAlign="center" color="green.100">{title}</Heading>
//               <Box fontSize="xl" color="grey.100" textAlign="center" mb={{ base: "6", sm: "2.5rem" }}><RichText text={description} /></Box>
//             </VStack>
//           </Box>
//         </Stack>
//       </Container>
//     </Main>
//   )
// }

// export default SinglePageTemplate

