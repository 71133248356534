import React, {useMemo} from 'react'
import { useQueryParam, StringParam } from "use-query-params";
import {graphql} from 'gatsby'
import SinglePageTemplate from '../../components/singlePageTemplate'
import usePrismicNewsReports from "../../hooks/usePrismicNewsReports";

const Conference = () => {
  const [id] = useQueryParam('id', StringParam)
  const data = usePrismicNewsReports()
  const conference = useMemo(() => data.conferences.find(item => item.id === id), [id])
  return (
    <SinglePageTemplate
      route="conference"
      title={data.conferenceTitle}
      image={conference?.image}
      description={conference?.description}
      usefulLinks={conference?.usefulLinks}
      />
  )
}

export default Conference

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
